// src/components/Sidepanel.js
import { FaSearch } from 'react-icons/fa';
import axiosInstance from '../../utlilites/axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContacts, setMessages, setSelectedUserId } from '../../redux/slices/chatSlice';

const Sidepanel = () => {

    const contacts = useSelector((state) => state.chat.contacts);
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('user/chat/specific_user_list/');
                if (response.data.status) {
                    dispatch(setContacts(response.data.data));
                }
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        fetchData();
    }, [dispatch]);

    const handleContactClick = async (userId) => {
        dispatch(setSelectedUserId(userId));
        try {
            const response = await axiosInstance.get(`user/chat/get_message/?receiver=${userId}`);
            console.log('INBOX MSGS', response.data.data);
            if (response.data.status) {
                dispatch(setMessages(response.data.data));
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };


    return (
        <>
            <div className="rounded-lg" id="sidepanel">
                <div id="profile">
                    <div className="wrap">
                        <img
                            id="profile-img"
                            src={`http://192.168.114.144:8002${user?.profile}`}
                            className="online"
                            alt=""
                        />
                        <p className="text-dark">{user?.fname} {user?.lname}</p>
                        <div id="status-options">
                            <ul>
                                <li id="status-online" className="active">
                                    <span className="status-circle"></span> <p>Online</p>
                                </li>
                                <li id="status-away">
                                    <span className="status-circle"></span> <p>Away</p>
                                </li>
                                <li id="status-busy">
                                    <span className="status-circle"></span> <p>Busy</p>
                                </li>
                                <li id="status-offline">
                                    <span className="status-circle"></span> <p>Offline</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="search" className="my-2">
                    <label htmlFor="">
                        <FaSearch />
                    </label>
                    <input type="text" placeholder="Search contacts..." />
                </div>
                <div id="contacts">
                    <ul className="list-unstyled">
                        {contacts?.map((contact, index) => (
                            <li
                                key={index}
                                className="contact text-dark"
                                onClick={() => handleContactClick(contact.id)}
                            >
                                <div className="wrap">
                                    <span className={`contact-status ${contact.status}`}></span>
                                    <img
                                        src={`http://192.168.114.144:8002${contact.profile}`}
                                        alt=""
                                    />
                                    <div className="meta">
                                        <p className="name">{contact.full_name}</p>
                                        <p className="preview">{contact.last_message}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div id="bottom-bar">
                    <button
                        style={{
                            borderTopLeftRadius: '15px',
                            borderBottomLeftRadius: '15px',
                        }}
                        id="addcontact"
                    >
                        Add contact
                    </button>
                    <button
                        style={{
                            borderTopRightRadius: '15px',
                            borderBottomRightRadius: '15px',
                        }}
                        id="settings"
                    >
                        Settings
                    </button>
                </div>
            </div>
        </>
    );
};

export default Sidepanel;
