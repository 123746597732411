import axiosInstance from "./axios";

const setSession = (token, user) => {
  if (token) {
    // localStorage.setItem("user", user);
    localStorage.setItem("accesstoken", token);
    localStorage.setItem("user", JSON.stringify(user));
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem("accesstoken");
    // localStorage.removeItem("user");
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};
const clearSession = () => {
  localStorage.removeItem("accesstoken");
  localStorage.removeItem("user");
  delete axiosInstance.defaults.headers.common.Authorization;
};
export { setSession, clearSession };


