import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utlilites/axios";
import { clearSession, setSession } from "../../utlilites/jwt";
import { clearSelectedUserId } from "./chatSlice";


const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null,
        isAuthenticated: false,
        status: "idle",
        error: null,
        isForgotPasswordFlow: false,
    },
    reducers: {
        loginSuccess: (state, action) => {
            const { token, data } = action.payload;
            setSession(token, data);
            state.user = data;
            state.token = token;
            state.isAuthenticated = true;
            state.status = "succeeded";
            state.error = null;
        },
        loginFailure: (state, action) => {
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
            state.status = "failed";
            state.error = action.payload;
        },
        logout: (state) => {
            clearSession();
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
            state.status = "idle";
            state.error = null;
            state.isForgotPasswordFlow = false;
        },
        isForgotPasswordFlow: (state, action) => {
            state.isForgotPasswordFlow = action.payload;
        },
    },
});

export const { loginSuccess, loginFailure, logout, isForgotPasswordFlow } = authSlice.actions;

export const login = (credentials) => async (dispatch) => {
    try {
        const response = await axiosInstance.post("user/authentication/login/", credentials);
        const { status, message, token, data } = response.data;
        if (status) {
            dispatch(loginSuccess({ token, data }));
        } else {
            dispatch(loginFailure(message));
        }
    } catch (error) {
        console.log("RESPONSE", error);
        dispatch(loginFailure(error.message));
    }
};
export const logoutAction = () => (dispatch) => {
    dispatch(logout());
    dispatch(clearSelectedUserId());
};

export default authSlice.reducer;
