import React, { useEffect, useState } from 'react'
import axiosInstance from '../../utlilites/axios';
import "./Profile.css"

const Profile = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("user/profile/view_profile/");
        setUserData(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="profile-page">
        <div className="form">
          {userData && (
            <>
              <h1 className="text-sm text-md-lg text-lg-xl customs font-weight-bold text-secondary">
                {userData.fname} {userData.lname}
              </h1>
              <img
                className="rounded-circle profileimage"
                src={`http://192.168.114.144:8002${userData?.profile}`}
                alt="Profile"
              />

              <div>
                <div className="d-flex justify-content-center justify-content-around mt-4 flex-wrap">
                  <p className="fs-5 fontcus">
                    <span className=" font-weight-bold text-secondary">
                      Email:
                    </span>{" "}
                    <span className="text-secondary cus">
                      {userData.email}{" "}
                    </span>
                  </p>

                  <p className="fs-5 fontcus">
                    <span className=" font-weight-bold text-secondary">
                      Contact:{" "}
                    </span>{" "}
                    <span className="text-secondary cus">
                      {userData.contact}{" "}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
