import {  useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./OTPVerification.css"
import { useSnackbar } from "notistack";
import axiosInstance from '../../utlilites/axios';

const OTPVerification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state ? location.state.id : null;
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = Yup.object().shape({
        code: Yup.number().required('Code is required').typeError('Code must be a number'),
    });


    const handleChangePassword = async (values) => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('token', values.code);

        try {
            const response = await axiosInstance.post("user/forgotpassword/token_check/", formData);
            console.log("response.status", response);
            if (response.status === 200) {
                navigate("/new-password", { state: { id } })
                enqueueSnackbar(response.data.message, { variant: 'success' });
            }
        } catch (error) {
            console.error("Error changing password:", error);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const formik = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema,
        onSubmit: values => {
            handleChangePassword(values);
        },
    });

    return (
        <>
            <div className="login-page">
                <div className="form">
                    <h1 className='text-dark'>Verify OTP</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            name="code"
                            className='mt-2'
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.code && formik.errors.code ? (
                            <div className="error ms-1">{formik.errors.code}</div>
                        ) : null}
                        <button className='mt-3' type="submit">Verify OTP</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default OTPVerification;
