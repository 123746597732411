import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utlilites/axios";

const signupSlice = createSlice({
    name: "signup",
    initialState: {
        user: null,
        status: "idle",
        error: null,
    },
    reducers: {
        signupSuccess: (state, action) => {
            const { data } = action.payload;
            state.user = data;
            state.status = "succeeded";
            state.error = null;
        },
        signupFailure: (state, action) => {
            state.user = null;
            state.status = "failed";
            state.error = action.payload;
        },
        resetUser: (state) => {
            state.user = null;
            state.status = "idle";
            state.error = null;
        },
    },
});

export const { signupSuccess, signupFailure, resetUser } = signupSlice.actions;

export const signup = (userData) => async (dispatch) => {
    try {
        const response = await axiosInstance.post("user/authentication/signup/", userData);
        const { status, message, token, data } = response.data;
        if (status) {
            dispatch(signupSuccess({ token, data }));
        } else {
            dispatch(signupFailure(message));
        }
    } catch (error) {
        console.log("RESPONSE", error);
        dispatch(signupFailure(error.message));
    }
};

export default signupSlice.reducer;
