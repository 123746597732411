import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { resetUser, signup } from '../../redux/slices/signupSlice';
import { useSnackbar } from "notistack";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(state => state.signup.user);
  // define validation schema
  const validationSchema = Yup.object().shape({
    fname: Yup.string().required('First Name is required'),
    lname: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    contact: Yup.string().required('Contact is required')
  });

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      password: '',
      contact: ''
    },
    validationSchema,
    onSubmit: values => {
      handleSignup(values);
    },
  });

  const handleSignup = (values) => {
    console.log(values);
    try {
      dispatch(signup(values));
    } catch (error) {
      console.error("Signup failed:", error);
    }
  };
  useEffect(() => {
    if (user) {
      navigate('/');
      enqueueSnackbar('User successfully Registered', { variant: 'success' });
      dispatch(resetUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch, enqueueSnackbar, navigate]);

  return (
    <>
      <div className="login-page">
        <div className="form">
          <h1 className='text-dark'>Register</h1>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              placeholder="First Name"
              name="fname"
              value={formik.values.fname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.fname && formik.errors.fname ? (
              <div className="error">{formik.errors.fname}</div>
            ) : null}
            <input
              className="mt-3"
              type="text"
              placeholder="Last Name"
              name="lname"
              value={formik.values.lname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lname && formik.errors.lname ? (
              <div className="error">{formik.errors.lname}</div>
            ) : null}
            <input
              className="mt-3"
              type="email"
              placeholder="Enter email address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
            <input
              className="mt-3"
              type="password"
              placeholder="Enter Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error">{formik.errors.password}</div>
            ) : null}
            <input
              className="mt-3"
              type="password"
              placeholder="Contact"
              name="contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.contact && formik.errors.contact ? (
              <div className="error">{formik.errors.contact}</div>
            ) : null}
            <button className='mt-3' type="submit">create</button>
            <p className="message">Already have an account <Link to='/'>Sign in</Link></p>
          </form>
        </div>
      </div>
    </>
  )
}

export default Signup
