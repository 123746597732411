import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./ChangePassword.css"
import { useSnackbar } from "notistack";
import axiosInstance from '../../utlilites/axios';

const NewPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state ? location.state.id : null;
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().required('New Password is required').min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const handleChangePassword = async (values) => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('password', values.newPassword);

        try {
            const response = await axiosInstance.post("user/forgotpassword/conformation/", formData);
            if (response.status === 200) {
                navigate("/chat");
                enqueueSnackbar(response.data.message, { variant: 'success' });
            }
        } catch (error) {
            console.error("Error changing password:", error);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema,
        onSubmit: values => {
            handleChangePassword(values);
        },
    });

    return (
        <>
            <div className="login-page">
                <div className="form">
                    <h1 className='text-dark'>New Password</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <input
                            type="password"
                            placeholder="New Password"
                            name="newPassword"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className="error ms-1">{formik.errors.newPassword}</div>
                        ) : null}
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            name="confirmPassword"
                            className='mt-2'
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className="error ms-1">{formik.errors.confirmPassword}</div>
                        ) : null}
                        <button className='mt-3' type="submit">Change Password</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewPassword;
