import React from 'react'
import "./navbar.css"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import playpals from "../../images/playpals.PNG";
import { Button, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {  logoutAction } from '../../redux/slices/loginSlice';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector(state => state.login.isAuthenticated);
    const firstName = useSelector(state => state.login.user?.fname);
    const lastName = useSelector(state => state.login.user?.lname);

    const handleLogout = () => {
        dispatch(logoutAction());
    }

    return (
        <>
            <Navbar expand="md" className="">
                {/* <Container> */}
                <Navbar.Brand href="#home">
                    <div className="d-flex">
                        <img src={playpals} alt="" width={55} height={45} />
                        <h2 className='fw-bold ms-2 mt-2'>PlayPals</h2>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link className='text-center' href="#home">Find Match</Nav.Link>
                        <Nav.Link className='text-center' href="#link">Find Competition</Nav.Link>
                        <Nav.Link className='text-center' href="/chat">Chat</Nav.Link>
                        <Nav.Link className='text-center' href="#link">About</Nav.Link>
                        <Nav.Link className='text-center' href="#link">Contact</Nav.Link>

                    </Nav>
                    {isAuthenticated ? (
                        <>
                            <NavDropdown title={`Hi ${firstName} ${lastName}`} id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => navigate('/profile')}>Profile View</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/profile-edit')} >
                                    Profile Update
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/change-password')}>Change Password</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}>
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        </>
                    ) : (
                        <>
                            <div className="nav-button text-center me-2">
                                <Button className="btn-login">
                                    Login
                                </Button>
                            </div>

                            <div className="nav-button text-center ms-2">
                                <Button className="btn-signup" >
                                    Join us
                                </Button>
                            </div>
                        </>
                    )}
                </Navbar.Collapse>
                {/* </Container> */}
            </Navbar>
        </>
    )
}

export default NavBar;