import { createSlice } from '@reduxjs/toolkit';

export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        selectedUserId: null,
        messages: [],
        contacts: [],
    },
    reducers: {
        setSelectedUserId: (state, action) => {
            state.selectedUserId = action.payload;
        },
        setMessages: (state, action) => {
            state.messages = action.payload;
        },
        setContacts: (state, action) => {
            state.contacts = action.payload;
        },
        clearSelectedUserId: (state) => {
            state.selectedUserId = null;
        },
    },
});

export const { setSelectedUserId, setMessages, setContacts, clearSelectedUserId } = chatSlice.actions;

export const selectSelectedUserId = (state) => state.chat.selectedUserId;
export const selectMessages = (state) => state.chat.messages;
export const selectContacts = (state) => state.chat.contacts;

export default chatSlice.reducer;
