 import defalImage from '../../images/NO-INTER-NET.png'
 import { IoMdLock } from "react-icons/io";

const DefaultChat=()=>{
return(
    <>
    <div className='mydiv  mt-5'>
    <div className="">
    <img src={defalImage} alt="chatApp"/>
        <h3 className='pla text-center mt-2'> Playpals Chating App</h3>
        <p className='aa mt-3'>Enable private messaging functionality for users in the PlayPals game chat app.</p>
      <p  className='text-center aa mt-5'><span><IoMdLock className='mb-1' /></span><span className='ms-1'>Your personal messages are end-to-end encrypted</span></p>
    </div>
    </div>
    </>
)

}

export default DefaultChat