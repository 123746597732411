import React from 'react'
import Sidepanel from "../../Components/sidepanel/Sidepanel"
import ContactProfile from "../../Components/contactProfile/ContactProfile"
import Inbox from '../../Components/inbox/Inbox';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedUserId } from '../../redux/slices/chatSlice';
import DefaultChat from '../../Components/defaultChatBox/defaultChatBox';

const Main = () => {
    const selectedUserId = useSelector((state) => state.chat.selectedUserId);

    const dispatch = useDispatch();

    const handleContactClick = (userId) => {
        dispatch(setSelectedUserId(userId));
    };
    return (
        <>
            <div>
                <div id="frame">
                    <Sidepanel handleContactClick={handleContactClick} />
                    <div className="content">
                        {selectedUserId ? (
                            <>
                                <ContactProfile selectedUserId={selectedUserId} />
                                <Inbox />
                            </>
                        ) : (
                            <DefaultChat/>
                        )}


                    </div>
                </div>
            </div>
        </>
    )
}

export default Main