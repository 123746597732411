import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./login.css"
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/slices/loginSlice';
import { useSnackbar } from "notistack";

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(state => state.login.user);
    // define validation schema
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required')
    });

    const handleLogin = (values) => {
        try {
            dispatch(login(values));

        } catch (error) {
            console.error("Login failed:", error);
            enqueueSnackbar('Login failed', { variant: 'error' });
        }
    };

    // formik hook for managing form state and validation
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: values => {
            handleLogin(values);
        },
    });

    useEffect(() => {
        if (user) {
            navigate('/chat');
            enqueueSnackbar('User successfully logged in', { variant: 'success' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, navigate, enqueueSnackbar]);
    

    return (
        <>
            <div className="login-page">
                <div className="form">
                    <h1 className='text-dark customs3'>Login</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <input
                            type="email"
                            placeholder="Enter email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="error ms-1">{formik.errors.email}</div>
                        ) : null}
                        <input
                            type="password"
                            placeholder="Enter password"
                            name="password"
                            className='mt-3'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="error ms-1">{formik.errors.password}</div>
                        ) : null}
                        <button className='mt-3' type="submit">login</button>
                        <p className="message mt-1">Forgot Password? <Link to='/forgot-password'>Reset here</Link></p>
                        <p className="message">Not registered? <Link to='/signup'>Create an account</Link></p>
                    </form>
                </div>
            </div>
        </>
    )
}

export default LoginForm
