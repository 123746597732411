import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./ProfileUpdate.css";
import { useSnackbar } from "notistack";
import { useSelector } from 'react-redux';
import axiosInstance from '../../utlilites/axios';

const ProfileUpdate = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(state => state.login.user);

    const validationSchema = Yup.object().shape({
        fname: Yup.string().required('First Name is required'),
        lname: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        profile: Yup.mixed().test('fileSize', 'File too large', (value) => {
            if (!value) {
                return true;
            }
            return value.size <= 2000000; // 2MB
        }),
    });

    const handleSubmit = async (values) => {
        const formData = new FormData();
        formData.append('fname', values.fname);
        formData.append('lname', values.lname);
        formData.append('email', values.email);
        formData.append('profile', values.profile);

        try {
            const response = await axiosInstance.put("user/profile/update_profile/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200) {
                navigate("/chat")
                enqueueSnackbar("Profile Edit Successfully!", { variant: 'success' });
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const formik = useFormik({
        initialValues: {
            fname: user.fname || '',
            lname: user.lname || '',
            email: user.email || '',
            profile: null,
        },
        validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    return (
        <>
            <div className="ProfileUpdate-page">
                <div className="form">
                    <h1 className='text-dark custom'>Profile Edit</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <label htmlFor="" className='d-flex'>First Name:</label>
                        <input
                            type="text"
                            placeholder="First Name"
                            name="fname"
                            value={formik.values.fname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.fname && formik.errors.fname ? (
                            <div className="error ms-1">{formik.errors.fname}</div>
                        ) : null}
                        <label htmlFor="" className='d-flex'>Last Name:</label>
                        <input
                            type="text"
                            placeholder="Last Name"
                            name="lname"
                            value={formik.values.lname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.lname && formik.errors.lname ? (
                            <div className="error ms-1">{formik.errors.lname}</div>
                        ) : null}
                        <label htmlFor="" className='d-flex'>Email:</label>
                        <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="error ms-1">{formik.errors.email}</div>
                        ) : null}
                        <label htmlFor="" className='d-flex'>Profile Image:</label>
                        <input
                            type="file"
                            name="profile"
                            onChange={(event) => {
                                formik.setFieldValue("profile", event.currentTarget.files[0]);
                            }}
                        />
                        {formik.touched.profile && formik.errors.profile ? (
                            <div className="error ms-1">{formik.errors.profile}</div>
                        ) : null}
                        <button className='mt-3' type="submit">Update Profile</button>
                        <p className="message">Go back to <Link to='/chat'>Chat</Link></p>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ProfileUpdate;
