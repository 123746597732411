import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./ForgotPassword.css"
import { useSnackbar } from "notistack";
import axiosInstance from '../../utlilites/axios';
import { isForgotPasswordFlow } from '../../redux/slices/loginSlice';
import { useDispatch } from 'react-redux';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    const handleChangePassword = async (values) => {
        const formData = new FormData();
        formData.append('email', values.email);

        try {
            const response = await axiosInstance.post("user/forgotpassword/token_send/", formData);
            if (response.status === 201) {
                dispatch(isForgotPasswordFlow(true));
                navigate('/otp-verification', { state: { id: response.data.id } });
                enqueueSnackbar(response.data.message, { variant: 'success' });
            }
        } catch (error) {
            console.error("Error changing password:", error);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: values => {
            handleChangePassword(values);
        },
    });

    return (
        <>
            <div className="login-page">
                <div className="form">
                    <h1 className='text-dark customs2'>Forgot Password</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <input
                            type="email"
                            placeholder="Enter Your Email"
                            name="email"
                            className='mt-2'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="error ms-1">{formik.errors.email}</div>
                        ) : null}
                        <button className='mt-3' type="submit">Verify Email</button>
                        <p className="message">Go back to <Link to='/'>Login</Link></p>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;
