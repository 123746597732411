/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessages, setMessages } from '../../redux/slices/chatSlice';
import { FaPaperPlane } from 'react-icons/fa';
import axiosInstance from '../../utlilites/axios';
import io from 'socket.io-client';

const Inbox = () => {

    const messages = useSelector(selectMessages);
    const dispatch = useDispatch();
    const [newMessage, setNewMessage] = useState('');
    const selectedUserId = useSelector((state) => state.chat.selectedUserId);
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(()=>{
        const socket = io('http://192.168.114.144:8000');
            
        // Example: listening for 'connect' event
        socket.on('connect', () => {
          console.log('Connected to server');
        });
    
        socket.on('join', data => {
            console.log('Joined:', data);
        });

        socket.on('d4552b7f-7003-4167-a977-211839d41d7b', data => {
            console.log("data===>",data)
            const updatedMessages = [...messages, data.message];
                dispatch(setMessages(updatedMessages));
        });;
        
        // Cleanup function
        return () => {
          socket.disconnect();
        };
      },[])


    if (!messages) {
        return null; // Return null or a loading indicator
    }

    const handleSendMessage = async () => {
        try {
            const formData = new FormData();
            formData.append('message_type', 'text');
            formData.append('receiver', selectedUserId);
            formData.append('message', newMessage);
            const response = await axiosInstance.post('user/chat/send_message/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 201) {
                setNewMessage("");
                // Update the local state of messages to include the newly sent message
                const newMsg = {
                    id: response.data.id,
                    sender: user.id,
                    message: newMessage,
                };
                const updatedMessages = [...messages, newMsg];
                dispatch(setMessages(updatedMessages));
            }
            // You can handle the response as needed
        } catch (error) {
            console.error('Error sending message:', error);
            // You can handle the error as needed
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };




    return (
        <>
            <div className="messages" style={{
                backgroundImage: `url('/assets/chatBg.png')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}> 
                <ul>
                    {messages.map((msg) => {
                        const createdAt = new Date(msg.created_at);
                        const formattedTime = createdAt.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
                        return (
                            <li key={msg.id} className={msg.sender === user.id ? 'replies' : 'sent'}>
                                {/* <img src={} alt="" /> */}
                                <p>{msg.message}<br />
                                    <span className="">
                                        {formattedTime}
                                    </span>
                                </p>
                                {/* <div ref={messagesEndRef} /> */}
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="message-input">
                <div className="wrap">
                    <input
                        type="text"
                        className='rounded-lg text-dark'
                        style={{
                            backgroundColor: "#DDDDDD",
                            paddingLeft: "20px",
                            borderTopLeftRadius: "1px",
                            borderBottomLeftRadius: "1px",
                        }}
                        placeholder="Write your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    {/* <i className="fa fa-paperclip attachment" aria-hidden="true"></i> */}
                    <button
                        onClick={handleSendMessage}
                        style={{
                            backgroundColor: "#0b983a",
                            borderTopRightRadius: "10px",
                            // borderBottomRightRadius: "10px",
                        }}
                        className="submit"><FaPaperPlane /></button>
                </div>
            </div>
        </>
    );
}

export default Inbox