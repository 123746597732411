import { combineReducers } from '@reduxjs/toolkit';
import loginSlice from './slices/loginSlice';
import signupSlice from './slices/signupSlice';
import chatSlice from './slices/chatSlice';

const rootReducer = combineReducers({
    login: loginSlice,
    signup: signupSlice,
    chat: chatSlice,
});

export default rootReducer;