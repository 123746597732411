import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./ChangePassword.css"
import { useSnackbar } from "notistack";
import axiosInstance from '../../utlilites/axios';

const ChangePassword = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Old Password is required'),
        newPassword: Yup.string().required('New Password is required'),
    });

    const handleChangePassword = async (values) => {
        const formData = new FormData();
        formData.append('oldpassword', values.oldPassword);
        formData.append('password', values.newPassword);

        try {
            const response = await axiosInstance.post("user/authentication/changepassword/", formData);
            console.log("response.status", response.status);
            if (response.status === 200) {
                navigate("/chat")
                enqueueSnackbar(response.data.message, { variant: 'success' });
            }
        } catch (error) {
            console.error("Error changing password:", error);
            enqueueSnackbar(error.message, { variant: 'error' });
            // Handle error
        }
    };

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
        },
        validationSchema,
        onSubmit: values => {
            handleChangePassword(values);
        },
    });

    return (
        <>
            <div className="login-page">
                <div className="form">
                    <h1 className='text-dark'>Change Password</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <input
                            type="password"
                            placeholder="Old Password"
                            name="oldPassword"
                            value={formik.values.oldPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.oldPassword && formik.errors.oldPassword ? (
                            <div className="error ms-1">{formik.errors.oldPassword}</div>
                        ) : null}
                        <input
                            type="password"
                            placeholder="New Password"
                            name="newPassword"
                            className='mt-2'
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className="error ms-1">{formik.errors.newPassword}</div>
                        ) : null}
                        <button className='mt-3' type="submit">Change Password</button>
                        <p className="message">Go back to <Link to='/chat'>Chat</Link></p>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ChangePassword
