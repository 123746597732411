import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedUserId } from "../../redux/slices/chatSlice";

export default function ContactProfile({ selectedUserId }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.chat.contacts.find((contact) => contact.id === selectedUserId));

    const handleCloseInbox = () => {
        dispatch(clearSelectedUserId());
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="contact-profile">
                <div className="pt-3">
                    <img src={`http://192.168.114.144:8002${user.profile}`} alt="" />
                    <p>{user.full_name}</p>
                </div>
                <div className="social-media px-3">
                    <RxCross1 onClick={handleCloseInbox} />
                </div>
            </div>
        </>
    );
}
